














import {getVersion} from '@/utils/version';
import Vue from 'vue';

export default Vue.extend({
  name: 'Footer',
  data() {
    return {
      version: getVersion()
    }
  }
});
