






import Vue from 'vue';
import Footer from "@/components/Footer.vue";

export default Vue.extend({
  name: 'Home',
  components: {
    Footer
  },
});
